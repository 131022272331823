import { useEffect, useState } from "react";
import Button from "../Button";
import Icon from "../Icon";
import Tooltip from "../Tooltip";
import styles from "./ControlBar.module.css";

function MuteButton({ muted, handleMicMute, ...additionalProps }) {
	const buttonStyle = muted ? "destruct" : "base";
	return (
		<Button type={`${buttonStyle}`} style="round" onClick={handleMicMute} {...additionalProps}>
			{!muted ? (
				<Icon>
					<svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
						<path d="M24 26.85q-2.15 0-3.6-1.55-1.45-1.55-1.45-3.75V9q0-2.1 1.475-3.55Q21.9 4 24 4t3.575 1.45Q29.05 6.9 29.05 9v12.55q0 2.2-1.45 3.75-1.45 1.55-3.6 1.55Zm0-11.4ZM24 42q-.65 0-1.075-.425-.425-.425-.425-1.075v-5.3q-4.9-.55-8.375-3.925Q10.65 27.9 10.1 23.15q-.1-.65.375-1.125t1.175-.475q.55 0 .95.4.4.4.5.95.55 4.05 3.65 6.725Q19.85 32.3 24 32.3t7.25-2.675q3.1-2.675 3.65-6.725.1-.55.525-.95.425-.4.975-.4.7 0 1.15.475.45.475.35 1.125-.55 4.75-4.025 8.125Q30.4 34.65 25.5 35.2v5.3q0 .65-.425 1.075Q24.65 42 24 42Zm0-18.15q.9 0 1.475-.675.575-.675.575-1.625V9q0-.85-.6-1.425Q24.85 7 24 7t-1.45.575q-.6.575-.6 1.425v12.55q0 .95.575 1.625T24 23.85Z" />
					</svg>
				</Icon>
			) : (
				<Icon>
					<svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
						<path d="M34.3 29.95 32.15 27.8Q33 26.75 33.475 25.5Q33.95 24.25 34.1 22.9Q34.2 22.35 34.625 21.95Q35.05 21.55 35.6 21.55Q36.3 21.55 36.75 22.025Q37.2 22.5 37.1 23.15Q36.85 25 36.15 26.75Q35.45 28.5 34.3 29.95ZM27.9 23.55 25.25 20.95V9.05Q25.25 8.2 24.65 7.6Q24.05 7 23.2 7Q22.35 7 21.75 7.6Q21.15 8.2 21.15 9.05V16.8L18.15 13.8V9.05Q18.15 6.95 19.625 5.475Q21.1 4 23.2 4Q25.3 4 26.775 5.475Q28.25 6.95 28.25 9.05V21.55Q28.25 21.95 28.175 22.55Q28.1 23.15 27.9 23.55ZM23.05 18.7Q23.05 18.7 23.05 18.7Q23.05 18.7 23.05 18.7Q23.05 18.7 23.05 18.7Q23.05 18.7 23.05 18.7ZM40.55 44.3 2.75 6.5Q2.35 6.1 2.35 5.525Q2.35 4.95 2.75 4.55Q3.15 4.15 3.7 4.15Q4.25 4.15 4.65 4.55L42.5 42.4Q42.9 42.8 42.9 43.35Q42.9 43.9 42.5 44.3Q42.1 44.7 41.525 44.7Q40.95 44.7 40.55 44.3ZM21.7 40.5V35.2Q16.8 34.65 13.325 31.275Q9.85 27.9 9.3 23.15Q9.2 22.5 9.675 22.025Q10.15 21.55 10.85 21.55Q11.4 21.55 11.8 21.95Q12.2 22.35 12.3 22.9Q12.85 26.95 15.95 29.625Q19.05 32.3 23.2 32.3Q25.1 32.3 26.85 31.675Q28.6 31.05 30.05 29.95L32.2 32.1Q30.65 33.4 28.75 34.175Q26.85 34.95 24.7 35.2V40.5Q24.7 41.15 24.275 41.575Q23.85 42 23.2 42Q22.55 42 22.125 41.575Q21.7 41.15 21.7 40.5Z" />
					</svg>
				</Icon>
			)}
		</Button>
	);
}

function CamButton({ muted, handleCameraMute, ...additionalProps }) {
	const buttonStyle = muted ? "destruct" : "base";
	return (
		<Button type={`${buttonStyle}`} style="round" onClick={handleCameraMute} {...additionalProps}>
			{!muted ? (
				<Icon>
					<svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
						<path d="M7 40Q5.8 40 4.9 39.1Q4 38.2 4 37V11Q4 9.8 4.9 8.9Q5.8 8 7 8H33Q34.2 8 35.1 8.9Q36 9.8 36 11V21.75L42.7 15.05Q43.05 14.7 43.525 14.875Q44 15.05 44 15.55V32.45Q44 32.95 43.525 33.125Q43.05 33.3 42.7 32.95L36 26.25V37Q36 38.2 35.1 39.1Q34.2 40 33 40ZM7 37H33Q33 37 33 37Q33 37 33 37V11Q33 11 33 11Q33 11 33 11H7Q7 11 7 11Q7 11 7 11V37Q7 37 7 37Q7 37 7 37ZM7 37Q7 37 7 37Q7 37 7 37V11Q7 11 7 11Q7 11 7 11Q7 11 7 11Q7 11 7 11V37Q7 37 7 37Q7 37 7 37Z" />
					</svg>
				</Icon>
			) : (
				<Icon>
					<svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
						<path d="m42.7 32.95-6.7-6.7v5.55l-3-3V11H15.2l-3-3H33q1.2 0 2.1.9.9.9.9 2.1v10.75l6.7-6.7q.35-.35.825-.175t.475.675v16.9q0 .5-.475.675-.475.175-.825-.175ZM41.35 45.6 2.95 7.2q-.4-.4-.4-1T3 5.15q.45-.45 1.05-.45.6 0 1.05.45l38.4 38.4q.4.4.4 1t-.45 1.05q-.45.45-1.05.45-.6 0-1.05-.45ZM24.2 20ZM7.95 8l3 3H7v26h26v-3.95l3 3V37q0 1.2-.9 2.1-.9.9-2.1.9H7q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8Zm11.9 16.1Z" />
					</svg>
				</Icon>
			)}
		</Button>
	);
}

function ScreenShareButton({ active, handleScreenShare, ...additionalProps }) {
	const buttonStyle = active ? "primary" : "base";
	return (
		<Button type={`${buttonStyle}`} style="round" onClick={handleScreenShare} {...additionalProps}>
			<Icon>
				{active ? (
					<svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" id="endShare">
						<path d="m27.6 23.75-5.4-5.4h4.3v-1.7q0-.5.475-.675.475-.175.825.175l3.15 3.15q.05.05.25.55 0 .1-.25.55Zm12.65 12.7-3-3h3.45V9.3H13.1l-3-3h30.6q1.2 0 2.1.9.9.9.9 2.1v24.15q0 1.3-1 2.2-1 .9-2.45.8Zm.55 9.05L37 41.75H3.5q-.65 0-1.075-.425Q2 40.9 2 40.25q0-.65.425-1.075.425-.425 1.075-.425H34l-2.3-2.3H7.35q-1.2 0-2.1-.9-.9-.9-.9-2.1V9.1l-1.7-1.7q-.45-.45-.45-1.075t.45-1.075q.45-.45 1.075-.45t1.075.45L42.95 43.4q.45.45.45 1.05 0 .6-.45 1.05-.45.45-1.075.45T40.8 45.5ZM19.65 24.4v2.75q0 .65-.425 1.075-.425.425-1.075.425-.65 0-1.075-.425-.425-.425-.425-1.075v-3q0-.5.1-1.125t.35-1.175L7.35 12.1v21.35H28.7Zm4.4-2.6Zm-6 .95Z" />
					</svg>
				) : (
					<svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" id="startShare">
						<path d="M18.15 28.65q.65 0 1.075-.425.425-.425.425-1.075v-3q0-1.2.8-2t2-.8h4.05v1.7q0 .5.475.675.475.175.825-.175l3.15-3.15q.05-.05.25-.55 0-.1-.25-.55l-3.15-3.15q-.35-.35-.825-.175t-.475.675v1.7h-4.05q-2.4 0-4.1 1.7-1.7 1.7-1.7 4.1v3q0 .65.425 1.075.425.425 1.075.425ZM7 36q-1.25 0-2.125-.875T4 33V9q0-1.25.875-2.125T7 6h34q1.25 0 2.125.875T44 9v24q0 1.25-.875 2.125T41 36Zm0-3h34V9H7v24Zm-3.5 9q-.65 0-1.075-.425Q2 41.15 2 40.5q0-.65.425-1.075Q2.85 39 3.5 39h41q.65 0 1.075.425Q46 39.85 46 40.5q0 .65-.425 1.075Q45.15 42 44.5 42ZM7 9v24V9Z" />
					</svg>
				)}
			</Icon>
		</Button>
	);
}

function StreamButton({ isLive, handleStream, loading, disabled, ...additionalProps }) {
	const buttonStyle = isLive ? "destruct" : "primary";
	const buttonContent = isLive ? "Stop" : "Start";

	return (
		<Button
			type={`${buttonStyle}`}
			style="roundedText"
			onClick={handleStream}
			fullWidth={"responsive"}
			disabled={disabled}
			loading={loading}
			{...additionalProps}>
			{buttonContent}
		</Button>
	);
}

export default function ControlBar({
	isLive,
	streamLoading,
	isDesktop,
	videoPermissions,
	micMuted,
	camMuted,
	screenShareActive,
	handleMicMute,
	handleCameraMute,
	handleScreenShare,
	handleStream,
}) {
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setMounted(true);
	}, []);

	// Only render this component in a browser.
	return (
		mounted && (
			<div className={styles.controlBar}>
				<Tooltip content={`${micMuted ? "Unmute" : "Mute"}`}>
					<MuteButton muted={micMuted} handleMicMute={handleMicMute} disabled={!videoPermissions} />
				</Tooltip>
				<Tooltip content={`${camMuted ? "Show camera" : "Hide camera"}`}>
					<CamButton muted={camMuted} handleCameraMute={handleCameraMute} disabled={!videoPermissions} />
				</Tooltip>
				{isDesktop && (
					<Tooltip content={`${screenShareActive ? "Stop sharing" : "Share your screen"}`}>
						<ScreenShareButton
							active={screenShareActive}
							handleScreenShare={handleScreenShare}
							disabled={!videoPermissions}
						/>
					</Tooltip>
				)}

				<StreamButton
					isLive={isLive}
					handleStream={handleStream}
					loading={streamLoading}
					disabled={!videoPermissions || streamLoading}
				/>
			</div>
		)
	);
}
