import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "reactstrap";
import axios from "../components/Hooks/axios";
import useHomeValidate from "../components/Hooks/useHomeValidate";
import Post from "../components/Post";
import styles from "../css/ProfessionalHome.module.scss";
import CreatePost from "./CreatePost.component";
import CreateClass from "./MyClass/CreateClass";
import Header from "./Professional_header";

function HomePageProfessional() {
	useHomeValidate();
	const navigate = useNavigate();
	async function getData(url = "", methods = "") {
		const response = await fetch(url, {
			method: methods,
			headers: {
				"Content-Type": "application/json",
				authorization: localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token"),
			},
		});
		return response.json();
	}

	const [modal, setModal] = useState(false);
	const [showCreateClass, setShowCreateClass] = useState(false);

	const [name, setName] = useState("");
	const [reload, setReload] = useState(false);
	const [allClasses, setAllClasses] = useState([]);
	const [allClassesLoading, setAllClassesLoading] = useState(false);
	const [offsetAllClasses, setOffsetAllClasses] = useState(0);
	const [liveCourse, setLiveCourse] = useState([]);
	const [liveClassLoading, setLiveClassLoading] = useState(false);
	const { userId } = useSelector((state) => state.auth);

	useEffect(() => {
		getData(process.env.REACT_APP_BASE_URL + "/auth/get-user-details?user_id=" + localStorage.getItem("user_id"), "GET").then(
			(data) => {
				if (data.status == true) {
					setName(data.data.data.full_name);
					console.log("User detail", data.data.data.full_name);
				} else {
					console.log("incorrect");
				}
			}
		);
	}, []);

	useEffect(() => {
		setAllClassesLoading(true);
		axios
			.get(`/class/getClass?offset=${offsetAllClasses}`)
			.then(({ data }) => {
				if (data.status) {
					setAllClasses(data.data);
					setAllClassesLoading(false);
				}
			})
			.catch((e) => {
				setAllClassesLoading(false);
				console.log(e);
			});
	}, [offsetAllClasses]);

	useEffect(() => {
		if (userId === "") return;
		setLiveClassLoading(true);
		axios
			.get(`/class/getclassByUserid/${userId}`)
			.then(({ data }) => {
				console.log(data.data);
				if (data.status) {
					setLiveCourse(data.data);
					setLiveClassLoading(false);
				}
			})
			.catch((e) => {
				setLiveClassLoading(false);
				console.log(e);
			});
	}, [userId]);

	const [e, setBottom] = useState("");
	const [page, setPage] = useState(0);

	function ReachBottom(e) {
		const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
		if (bottom) {
			setPage((prev) => prev + 10);
			console.log(bottom, "reach Bottom", page);
		}
	}

	const slider = document.querySelector(".LiveClass");
	let isDown = false;
	let startX;
	let scrollLeft;

	slider?.addEventListener("mousedown", (e) => {
		isDown = true;
		startX = e.pageX - slider.offsetLeft;
		scrollLeft = slider.scrollLeft;
	});
	slider?.addEventListener("mouseleave", () => {
		isDown = false;
	});
	slider?.addEventListener("mouseup", () => {
		isDown = false;
	});
	slider?.addEventListener("mousemove", (e) => {
		if (!isDown) return;
		e.preventDefault();
		const x = e.pageX - slider.offsetLeft;
		const walk = (x - startX) * 2; //scroll-fast
		slider.scrollLeft = scrollLeft - walk;
	});

	const slider2 = document.querySelector(".AllClass");
	let isDown2 = false;
	let startX2;
	let scrollLeft2;

	slider2?.addEventListener("mousedown", (e) => {
		isDown2 = true;
		startX2 = e.pageX - slider2.offsetLeft;
		scrollLeft2 = slider2.scrollLeft;
	});
	slider2?.addEventListener("mouseleave", () => {
		isDown2 = false;
	});
	slider2?.addEventListener("mouseup", () => {
		isDown2 = false;
	});
	slider2?.addEventListener("mousemove", (e) => {
		if (!isDown2) return;
		e.preventDefault();
		const x2 = e.pageX - slider2.offsetLeft;
		const walk2 = (x2 - startX2) * 2; //scroll-fast
		slider2.scrollLeft = scrollLeft2 - walk2;
	});

	return (
		<div className="professionalMainContainer">
			<Modal className=" text-white createPostModel" size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
				<CreatePost modelProp={setModal} setReload={setReload} />
			</Modal>
			{showCreateClass && <CreateClass setShowCreateClass={setShowCreateClass} />}
			<Header />

			<div className={` ProfessionalBody ${styles.Home}`}>
				<div className={styles.ProfessionalRight}>
					<h6>Create Post</h6>

					<div className="display-flex">
						<button className="supportSubmitBtn" onClick={() => setShowCreateClass(true)}>
							Create Class
						</button>
						&nbsp;&nbsp;
						<button className="supportSubmitBtn" onClick={() => navigate("/Professional-LiveClass")}>
							Live Class
						</button>
					</div>

					<div className={`${styles.LiveCourseContainerMain}`}>
						<h6>My Course</h6>
						<div className={` LiveClass ${styles.LiveClassContainer}`}>
							{liveClassLoading ? (
								<div className={styles.loadingDiv}>
									<ColorRing
										visible={true}
										height="100"
										width="100"
										ariaLabel="blocks-loading"
										wrapperClass="blocks-wrapper"
										colors={["#ffc107", "#0dcaf0", "#0d6efd", "#d63384", "#6610f2"]}
									/>
								</div>
							) : liveCourse.length === 0 ? (
								"No Classes"
							) : (
								liveCourse.map((data, i) => (
									<div
										className={styles.liveClassBox}
										key={i}
										onClick={() => navigate(`/Professional-ClassInfo/${data._id}`)}>
										<h6>{data.courseTitle}</h6>
										<p>{data.courseDescription}</p>
										<span>
											{"On " +
												data.createdAt.split("T")[0] +
												" at " +
												data.createdAt.split("T")[1].split(".")[0]}
										</span>
									</div>
								))
							)}
						</div>
					</div>

					<div className={styles.AllClassContainerMain}>
						<h6>All Courses</h6>
						<div className={` AllClass ${styles.AllClassContainer}`}>
							{allClassesLoading ? (
								<div className={styles.loadingDiv}>
									<ColorRing
										visible={true}
										height="100"
										width="100"
										ariaLabel="blocks-loading"
										wrapperClass="blocks-wrapper"
										colors={["#ffc107", "#0dcaf0", "#0d6efd", "#d63384", "#6610f2"]}
									/>
								</div>
							) : allClasses.length === 0 ? (
								"No Classes"
							) : (
								allClasses.map((data, i) => (
									<div
										key={i}
										className={styles.allClassesBox}
										onClick={() => navigate(`/Professional-ClassInfo/${data._id}`)}>
										<img src={data.coverpic} alt="" />
									</div>
								))
							)}
						</div>
						<div className="AlbumPagination">
							<button
								disabled={offsetAllClasses === 0 && true}
								onClick={() => setOffsetAllClasses(offsetAllClasses - 10)}>
								{"<"} Prev
							</button>
							<button
								disabled={allClasses?.length < 10 && true}
								onClick={() => setOffsetAllClasses(offsetAllClasses + 10)}>
								Next {">"}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default HomePageProfessional;
