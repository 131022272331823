import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useRef, useState } from "react";
import { FaStar } from "react-icons/fa";
import { MdOutlineWatchLater } from "react-icons/md";
import { ThreeCircles } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useEnrollBtnMutation, useFetchUserDetailsQuery } from "../../Redux/api/authApi";
import { createNotification } from "../../components/Hooks/CreateNotification";
import axios from "../../components/Hooks/axios";
import useUserValidate from "../../components/Hooks/useUserValidate";
import PDF from "../../components/svgCom/PDF";
import PlayIcon from "../../components/svgCom/PlayIcon";
import InfoIcon from "../../images/InfoIcon.png";
import Start from "../../images/Star 2.png";
import supportIcon from "../../images/supportIcon.png";
import Header from "../header.component";

function CourseDetailsAndEnroll() {
	useUserValidate();
	const navigate = useNavigate();
	const { classId } = useParams();

	const [ClassData, setClassData] = useState([]);
	const [reviews, setReviews] = useState([]);
	const [name, setName] = useState("");
	const [ActiveLectureId, setActiveLectureId] = useState("");
	const [lecture, setLecture] = useState([]);
	const [infoShow, setInfoShow] = useState(false);
	const [supportShow, setSupportShow] = useState(false);
	const [videoLink, setVideoLink] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const [progress, setProgress] = useState(0);
	const lectureRef = useRef();
	const videoRef = useRef(null);
	const [reviewText, setReviewText] = useState("");
	const [reload, setReload] = useState(0);
	const [disableReviewBtn, setDisableReviewBtn] = useState(false);
	const [reviewReload, setReviewReload] = useState(false);
	const [reviewStar, setReviewStar] = useState(5);
	const [enrollBtn] = useEnrollBtnMutation();
	const [enrollStatus, setEnrollStatus] = useState(false);
	const { user, userId } = useSelector((state) => state.auth);
	const { data = [], isFetching } = useFetchUserDetailsQuery(
		localStorage.getItem("user_id") || sessionStorage.getItem("user_id")
	);

	useEffect(() => {
		let es;
		if (!isFetching && user.enrolled.length != 0) {
			es = user.enrolled.filter((item) => item.course_id.includes(classId));
			if (es.length > 0) setEnrollStatus(true);
			else setEnrollStatus(false);
		}
	}, [isFetching, classId]);

	useEffect(() => {
		setIsLoading(true);
		axios
			.get("/class/getOneClass/" + classId)
			.then(({ data }) => {
				if (data.status == true) {
					setClassData(data.data);
					setLecture(data.data.media);
					setName(data.data?.created_by?.full_name);
					setIsLoading(false);
				} else {
					console.log("incorrect");
				}
			})
			.catch((error) => console.log("error", error));
	}, [classId]);

	useEffect(() => {
		setReviewReload(true);
		axios
			.get("/class/getOneClass/" + classId)
			.then(({ data }) => {
				if (data.status == true) {
					setReviews(data.data.review);
					setReviewReload(false);
				} else {
					console.log("incorrect");
				}
			})
			.catch((error) => console.log("error", error));
	}, [ classId]);
//reload,
	const totalReview = reviews.reduce((currentReview, review) => {
		return currentReview + parseInt(review.rating);
	}, 0);
	const Rating = (totalReview / reviews.length).toFixed(1);

	const totalTime = lecture.reduce((currenTime, time) => {
		return currenTime + time.lectureDuration;
	}, 0);

	const totalMinutes = Math.floor(totalTime / 60);
	const seconds = totalTime % 60;
	const hours = Math.floor(totalMinutes / 60);
	const minutes = totalMinutes % 60;

	const handelProgress = (e) => {
		let progress = Math.ceil((e.target.currentTime / e.target.duration) * 100);
		setProgress(progress);
		if (progress == 100) {
			setProgress(0);
			setActiveLectureId("");
			setVideoLink("");
		}
	};

	const handelReviewPost = () => {
		if (reviewText == "") return toast.warn("Empty !!");
		setDisableReviewBtn(true);

		const formData = new FormData();
		formData.append("created_by", localStorage.getItem("user_id"));
		formData.append("class_id", classId);
		formData.append("rating", reviewStar);
		formData.append("reviewText", reviewText);

		axios
			.post("/class/reviewClass", formData)
			.then(({ data }) => {
				if (data.status === true) {
					setReload(Math.random());
					setReviewText("");
					setDisableReviewBtn(false);
				}
			})
			.catch((e) => console.log(e));
	};

	const handelEnrollBtn = () => {
		enrollBtn(classId);
		createNotification(userId, "Class", `You enrolled for ${ClassData.courseTitle}`);
		createNotification(ClassData?.created_by?._id, "Class", `${user.full_name} enrolled for ${ClassData.courseTitle} course`);
	};

	return (
		<div className="HomeMainContainer">
			<Header />

			<div className="UserCourseDetails">
				<div className="UserCourseDetailsTopCont">
					<div className="UserCourseDetailsTopContOverlay" />
					<img
						className="UserCourseDetailsTopContImg"
						src={ClassData.coverpic}
						alt=""
						onDragStart={(e) => e.preventDefault()}
					/>
					<h2 style={{ zIndex: 5 }}>Learning</h2>
					<div className="UserCourseDetailsTopContInner">
						<div className="UserCourseDetailsTopContInner1">
							<button className="btn btn-outline-muted">Features</button>
							<button className="btn btn-outline-muted">
								<MdOutlineWatchLater /> {hours != 0 && hours + "H "}
								{minutes != 0 && minutes + "M "}
								{seconds != 0 && seconds + "S "}
								Course
							</button>
							<button className="btn btn-outline-muted paid">Paid</button>
						</div>

						<div className="UserCourseDetailsTopContInner2">
							<h3>{ClassData.courseTitle}</h3>
							<button
								disabled={enrollStatus}
								onClick={handelEnrollBtn}>
								{enrollStatus ? "Enrolled" : `Buy Course ₹${ClassData.courseFees}`}
							</button>
						</div>
						<div className="UserCourseDetailsTopContInner3">
							<p>
								<FaStar color="#FFCC15" /> {Rating === "NaN" ? "0" : Rating} ( {reviews.length} people Rated )
							</p>
							<h5 onClick={() => navigate("/instructor")}>
								Tought by <span>{name}</span>
							</h5>
						</div>
					</div>
				</div>

				<div className="UserCourseDetailsBottomCont">
					{infoShow && (
						<div className="InfoContainer">
							<div className="InfoContainerInner">
								<button type="button" onClick={() => setInfoShow(!infoShow)}>
									X
								</button>
								{ClassData.courseDescription}
							</div>
						</div>
					)}
					{supportShow && (
						<div className="InfoContainer SupportContainer">
							<div className="InfoContainerInner">
								<button type="button" onClick={() => setSupportShow(!supportShow)}>
									X
								</button>
								Feel free to contact us at{" "}
								<span
									style={{
										color: "rgb(106 70 246)",
										fontSize: "30px",
										fontWeight: 700,
										display: "block",
										margin: "30px 0px",
									}}>
									"support@gmail.com"
								</span>{" "}
								for your queries / concerns.
							</div>
						</div>
					)}
					<div className="ClassInfoLeft">
						<div className="ClassInfoLeftVideoContainer">
							<video controls autoPlay src={videoLink} ref={videoRef} onTimeUpdate={handelProgress}></video>
						</div>

						<div className="ClassInfoLeftLectureContainer" ref={lectureRef}>
							{isLoading ? (
								<ThreeCircles
									height="100"
									width="100"
									color="#4fa94d"
									wrapperStyle={{
										with: "100%",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
									wrapperClass=""
									visible={true}
									ariaLabel="three-circles-rotating"
									outerCircleColor=""
									innerCircleColor=""
									middleCircleColor=""
								/>
							) : (
								lecture.map((data, i) => {
									const totalMinutes = Math.floor(data.lectureDuration / 60);
									const seconds = data.lectureDuration % 60;
									const hours = Math.floor(totalMinutes / 60);
									const minutes = totalMinutes % 60;
									return (
										<div
											className={`LectureBox ${data._id == ActiveLectureId ? "activeLectureBox" : ""}`}
											key={i}>
											<div className="LectureBoxLeft">
												<h1>{i + 1}</h1>
												<div>
													<h1>{data.lectureTitle}</h1>
													{data._id == ActiveLectureId ? (
														<span>
															<p style={{ width: `${progress}%` }}></p>
														</span>
													) : (
														<p>
															{hours != 0 ? hours + " hrs" : null}{" "}
															{minutes != 0 ? minutes + " min" : null}{" "}
															{seconds != 0 ? seconds + " sec" : null}
														</p>
													)}
												</div>
											</div>
											<div className="LectureBoxRight">
												<button
													onClick={() => {
														if (!enrollStatus && i != 0) return toast.warn("Please Enroll first !!");
														if (ActiveLectureId == data._id) {
															setVideoLink("");
															setActiveLectureId("");
														} else {
															setVideoLink(data.videoUpload);
															setActiveLectureId(data._id);
														}
													}}>
													{data._id == ActiveLectureId ? (
														<img src="https://img.icons8.com/ios/50/ffffff/circled-pause.png" />
													) : (
														<PlayIcon />
													)}
												</button>
												<button
													onClick={() => {
														if (!enrollStatus && i != 0) return toast.warn("Please Enroll first !!");
														window.location.href = data.documentUpload;
													}}>
													<PDF />
												</button>
											</div>
										</div>
									);
								})
							)}
						</div>
					</div>

					<div className="ClassInfoRight">
						<div className="ratting">
						<h1>Courses Resources</h1>
						<h1 className="rattingshadow">
							<img src={Start} alt="" /> {Rating === "NaN" ? "0" : Rating}
						</h1>
						</div>
						<div className="RoundBoxContainer">
							<div className="RoundBoxDetail" onClick={() => setInfoShow(!infoShow)}>
								<div className="RoundBox">
									<img src={InfoIcon} alt="" />
								</div>
								<h2>Info</h2>
							</div>
							<div
								className="RoundBoxDetail"
								onClick={() => {
									navigate("/support");
									setSupportShow(!supportShow);
								}}>
								<div className="RoundBox">
									<img src={supportIcon} alt="" />
								</div>
								<h2>Support</h2>
							</div>
						</div>
						{/* <h1 className="OverallRatingText">
							Overall Rating <img src={Start} alt="" /> {Rating === "NaN" ? "0" : Rating}
						</h1> */}
						{enrollStatus && (
							<div className="ReviewPostInClass">
								<input
									type="text"
									placeholder="Post Review write hare"
									value={reviewText}
									onChange={(e) => setReviewText(e.target.value)}
								/>
								<label htmlFor="Rating">
									<select
										id="Rating"
										className="RatingUserDetail"
										defaultValue={reviewStar}
										onChange={(e) => setReviewStar(e.target.value)}>
										<option value="1">1</option>
										<option value="2">2</option>
										<option value="3">3</option>
										<option value="4">4</option>
										<option value="5">5</option>
									</select>
									<FaStar color="FFA800" fontSize={"25px"} />
								</label>

								<button disabled={disableReviewBtn} onClick={handelReviewPost}>
									Post Review
								</button>
							</div>
						)}
						<h1>
							Reviews <span>( {reviews.length == 0 ? "0" : reviews.length} Reviews )</span>
						</h1>

						<div className="CommentBoxContainer">
							{reviews.length === 0 && <h1>No Reviews yet</h1>}
							{reviewReload ? (
								<ThreeCircles
									height="100"
									width="100"
									color="#4fa94d"
									wrapperStyle={{
										with: "100%",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
									wrapperClass=""
									visible={true}
									ariaLabel="three-circles-rotating"
									outerCircleColor=""
									innerCircleColor=""
									middleCircleColor=""
								/>
							) : (
								[...reviews].reverse().map((data, index) => {
									return (
										<div className="commentBox" key={index}>
											<div className="commentBoxLeft">
												<img src={data?.created_by?.pic} alt="" />
												<div className="cmtTextCont">
													<h3 onClick={() => navigate(`/profile/${data?.created_by?._id}`)}>
														{data?.created_by?.full_name}
													</h3>
													<h4>{data.reviewText}</h4>
												</div>
											</div>

											<div className="commentBoxRight">
												<img src={Start} alt="" />
												<p>{data.rating}</p>
											</div>
										</div>
									);
								})
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default CourseDetailsAndEnroll;
