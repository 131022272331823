import React from "react";

import styles from "../css/UserHome.module.scss";
import Header from "./header.component";
import MobileHeader from "./mobileHeader";

function HomePage() {
	return (
		<div className={styles.UserHomeMain}>
			<Header />
			<MobileHeader />
			<div className={styles.UserHome}>
				<div className="text-info m-2">Will Be Designed Later </div>
			</div>
		</div>
	);
}
export default HomePage;
