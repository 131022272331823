import React from "react";
import { useNavigate } from "react-router-dom";
import useProfessionalValidate from "../components/Hooks/useProfessionalValidate";
import socialMediaImg2 from "../images/Vector (10).png";
import socialMediaImg3 from "../images/Vector (20).png";
import socialMediaImg4 from "../images/Vector (30).png";
import socialMediaImg5 from "../images/Vector (40).png";
import socialMediaImg1 from "../images/Vector.png";
import Header from "./Professional_header";

const ProfessionalTermAndCondition = () => {
	useProfessionalValidate();
	const navigate = useNavigate();
	let BtnArray = ["Support & Help", "Privacy Policy", "About"];

	function buttons(val, i) {
		return (
			<button
				key={i}
				onClick={() => {
					if (val === "Support & Help") navigate("/Professional-support");
					if (val === "About") navigate("/Professional-aboutUs");
					if (val === "Privacy Policy") navigate("/Professional-privacy");
				}}
				className="btn btn-outline-secondary accountBtn">
				{val}
			</button>
		);
	}

	return (
		<div className="professionalMainContainer">
			<Header />
			<h5 style={{ paddingLeft: "10px" }}>
				<span onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
					◄
				</span>{" "}
				TERM AND CONDITION
			</h5>
			<div className="ProfessionalBody">
				<div className="TextContent">
					<>
						<br />
						<p>Music book™ (“us”, “we”, or “our”) operates the website: www.dummy.in</p>
						<p>Music book™ manages/runs/leads https://dummycompany.co.in/. It is a registered private company in India.</p>
						<br />
						<h5>Terms & Conditions for Learners</h5>
						<div style={{ paddingLeft: "20px" }}>
							<p>
								The Music book™ is a music education provider that offers instrumental and vocal lessons taught by a select group
								of qualified teachers. Our online platform brings music lessons directly to the learner's home, providing a
								convenient and accessible way to learn. Additionally, we will host virtual music workshops, masterclasses, and
								other online events to further enrich the musical education experience.
							</p>
							<p>
								When a student, buyer, or learner books a lesson through our website, www.www.dummy.in, they will be directed to
								our Terms & Conditions and Privacy Policy pages during the payment process. Unless they indicate their agreement
								by checking the box provided, they will not be able to complete the transaction. By booking a lesson (or multiple
								lessons), the student, buyer, or learner is confirming that they have read and understood our policies and have no
								objections to them. This is demonstrated by their payment(s), which indicates that they fully understand and agree
								to adhere to everything stated in our Company Policies, Terms & Conditions and Privacy Policy. It is expected that
								they will not raise any objections or concerns regarding the contents of these pages.
							</p>
						</div>
						<br />

						<h5>Terms and Conditions, Company Policies for Music book™ Services</h5>
						<ul>
							<li>
								The fees for our lessons are subject to change, and both the Music book™ and our teachers reserve the right to
								adjust prices without prior notice. However, any slots that have already been sold will not be affected by sudden
								price changes, and only new bookings will be subject to the revised prices. While we aim to keep lesson fees
								stable, occasional adjustments may be necessary.
							</li>
							<li>
								If either the teacher or the student is unable to attend a scheduled lesson due to unforeseen circumstances, we
								will work with both parties to find a suitable makeup slot.
							</li>
							<li>
								Students/buyers/learners must have a reliable broadband connection for smooth online lessons. Slow internet speeds
								may interrupt the lesson, and we will not be responsible for such disruptions. Refunds will not be issued for
								interruptions caused by poor internet connectivity.
							</li>
							<li>
								Students/buyers/learners should only sign up for our service if they are comfortable with e-learning and have
								prior experience using our online video chat platform.{" "}
							</li>
							<li>
								Music book™ cannot be held responsible for slow internet speeds or technical issues related to broadband or
								internet services on the student or teacher end. It is a well-known fact that internet speeds can drop
								unexpectedly, and we cannot be held accountable for such issues, nor can we provide refunds. If the student or
								buyer agrees to these conditions, they may proceed with our service.
							</li>
							<li>
								Music book™ is not responsible for any technical issues that may arise with the video chat or screen sharing
								platform being used. It is widely recognized that technical errors are unpredictable and can occur without notice
								in any online medium. We cannot be held responsible for such issues and cannot offer refunds.
							</li>
							<li>
								In the event that the teacher is unable to conduct a lesson due to illness or an urgent matter, they will
								reschedule the lesson with the student. In this case, the student will be expected to cooperate and continue
								lessons
							</li>
							<li>
								Students are welcome to keep in touch with the teacher/instructor through our platform for lessons. However, for
								any official or management-related matters, students are requested to communicate with us for guidance. If any
								student attempts to offer private lessons or jobs to our teachers, they will be permanently terminated from our
								lessons for misusing our service. Our teachers are not allowed to accept any private offers from our students, and
								any such offers will be reported to us immediately.
							</li>
							<li>
								We carefully select our professional music teachers based on their skills, experience, and public profile.
								However, it is impossible for us to know the character of every individual involved with our service. If a teacher
								engages in any unlawful, unethical, or similar activity, the student may take necessary action as per the law.
								While we will provide full cooperation in such situations, our company will not be held liable for the teacher's
								actions. We advise learners to inform us promptly if they encounter any inappropriate behavior from a teacher,
								preferably with evidence, so that we can take necessary action under our authority.
							</li>
							<li>
								If a learner decides to have private lessons with a teacher recommended by www.dummy.in outside of the
								platform, then they will no longer be considered as customers of www.dummy.in / Music book™. In this case,
								if the teacher behaves inappropriately towards the learner, www.dummy.in / Music book™ will not be able to
								assist the student as the service is not being provided through our platform. It is against the law for teachers
								to offer private lessons or any other services to learners introduced by www.dummy.in / Music book™ without
								the knowledge and permission of www.dummy.in / Music book™. This same rule applies to the learners.
							</li>
							<li>
								Our teachers/tutors work with us as freelance professionals, so they may discontinue teaching our students
								suddenly. In such a situation, we suggest that students switch to another teacher/tutor for the same subject
								(instrument or voice) if possible and if the other teacher has available slots. If not, we will search for a new
								teacher/tutor for that particular instrument or voice lessons. If user is not satisfied, payment will be refunded.
								We will inform existing parents/students about the situation and expect their cooperation. By booking lessons with
								us, you acknowledge and accept this arrangement and service model. Music book™ cannot be held responsible for any
								such matter or inconvenience as stated in this point once you have read and paid for the lesson booking(s).
							</li>
							<li>
								Our single-slot booking option makes this process very convenient for parents/students and teachers/tutors. We
								sell one slot at a time, as well as more than one slot at a time, so the association we have with the
								customer/parent/student is limited to the number of slots that have been booked by them. By booking lessons with
								us, you acknowledge and accept this service model. Music book™(www.dummy.in) cannot be held responsible for
								any such matter or inconvenience as stated in this point once you have read and paid for the lesson booking(s).
							</li>
							<li>
								The personal information we collect from prospective learners/customers, including their name, email ID, and
								mobile number, is only used for communication purposes. We do not share this information with any third parties.
							</li>
						</ul>
						<br />
					</>

					<h5>Fees</h5>
					<ul>
						<li>
							The company reserves the right to modify the fees per lesson or per slot at any time without prior notice. The
							revision or increment in fees completely depends on the discretion of the Music book™ and can occur with or without
							any prior announcement.
						</li>
					</ul>
					<br />

					<h5>Refund</h5>
					<p>
						A refund will only be issued in case the teacher misses the lesson or if we fail to deliver the service as promised.
						Otherwise, refunds will not be provided.
					</p>
					<br />

					<h5>Cancellation of Music Lesson/Slot</h5>
					<ul>
						<li>Cancellations are only accepted within 24 hours of paying and booking the service/lesson.</li>
						<li>No cancellation will be accepted after 24 hours of the booking of the service/lesson.</li>
						<li>
							For cancellation, the buyer must send us an email with the request within 24 hours of making the payment, it will
							only be processed after receiving the email. The buyer can email us from the contact page or email us at
							support@gmail.com. It may take from 5 working days to 7 working days to get the cancellation fee credited to
							the buyer’s account.
						</li>
					</ul>
					<br />

					<h5>Payments</h5>
					<p>
						Music book™ will not be held responsible for any fraudulent activity related to payments as we do not collect any bank
						account or card details from our customers. We use Cashfree one of the leading payment gateway companies in India who
						does KYC verification, to handle payments for our website www.dummy.in. Therefore, Cashfree is solely liable
						for any payment-related issues. It is advised that customers only book lessons with us if they understand and agree to
						this matter. For further details about our privacy policy, please refer to our website.
					</p>
					<br />

					<h5>The Terms & Conditions, Rules & Regulations and Company-Policies for Online Music Workshop are mentioned below:</h5>
					<h5>Recital</h5>
					<ul>
						<li>
							The terms and conditions provided in this document apply to the specific Online Music Workshops for which the client
							(customer/student/parent) has received the link.
						</li>
						<li>
							The 'Online Music Workshop' pertains to any virtual music workshop organized by Music book™, including workshops
							focused on specific instruments like the Piano or Violin. All of these virtual workshops will be considered as
							'Online Music Workshops'.
						</li>
					</ul>
					<br />

					<h5>Details</h5>

					<ul>
						<li>
							<b>Date and Time: </b>The date and time for the online workshop will be as stated on the website page or in the
							introductory/promotional email.
						</li>
						<li>
							<b>Online Platform: </b>The virtual workshop will be conducted on our online platform.
						</li>
						<li>
							<b>Number of Participants: </b>The number of participants for the workshop will be specified in the
							introductory/promotional email, and it may differ depending on the workshop.
						</li>
						<li>
							<b>Workshop Duration: </b>Typically, the virtual workshops are scheduled for two days with each day lasting for
							ninety minutes. However, the specific duration for each workshop may vary and will be communicated in the respective
							introductory/promotional email.
						</li>
						<li>
							<b>Language of Instruction: </b>The language used for the workshop will be English.
						</li>
						<li>
							<b>Participation Fee: </b>The fee for participating in the workshop will be stated in the introductory/promotional
							email.
						</li>
						<li>
							<b>Certificate: </b>A 'Certificate of Participation' will be given to each participant via email as a soft copy
							without the Master and Project Head's signature. However, their names will be included in the certificate.
						</li>
						<li>
							By making a successful payment for any of Music book™'s online music workshop projects against the invoice raised by
							Music book™, the client (customer/student/parent) confirms that they have read, understood and agreed to all the
							terms and conditions mentioned in this document without any hesitation or confusion.
						</li>
						<li>
							Students are kindly requested to attend the workshop on time and adhere to any further guidelines provided during
							the workshop.
						</li>
						<li>Recording of the workshop sessions is strictly prohibited for all participants.</li>
						<li>
							The workshop will be conducted on our virtual platform, and by agreeing to participate, the student acknowledges
							that they understand how to use the platform and will do so at their own risk.
						</li>
						<li>
							Music book™ reserves the right to record the Online Music Workshop and use the footage and photos for promotional
							purposes. By participating in the workshop, the client (customer/student/parent) gives their consent for the usage
							of the footage and photos without any expectation of compensation and confirms that they will not hold Music book™
							liable for using the footage and photos.
						</li>
						<li>
							During the workshop session, all participants must maintain proper classroom etiquette, and Music book™ reserves the
							right to discontinue a student's participation for misconduct without any refunds.
						</li>
						<li>
							If Music book™ is unable to conduct the workshop, the full fee will be returned to the student within two weeks, and
							Music book™ will not be held responsible or liable for any other issues.
						</li>
						<li>
							The participation fee will not be refunded if the student does not attend the workshop or for any other reason from
							the client's side.
						</li>
						<li>
							The date and time of the workshop may change due to unforeseen circumstances, a request from the master, or other
							unpredictable situations. If such a change occurs, the client/student will be informed, and they may request a full
							refund within 48 hours of the announcement. The refund will be processed within 5 to 14 working days upon receipt of
							an official request letter.
						</li>
						<li>
							The information collected from prospective learners/customers, such as their name, email ID, and mobile number, is
							solely for communication purposes and will not be shared with anyone else.
						</li>
					</ul>
					<br />

					<h5>Changes To the Terms & Conditions and Company Policy</h5>
					<p>
						We reserve the right to make updates and changes to our Terms & Conditions and Company Policies. It is important for
						both new and existing customers to regularly check this page for any updates. If you are an active customer and we
						make changes related to the service you are using, we will inform you of the updates. By making a payment, you agree
						to the current Terms & Conditions and Company Policy. If you are a former customer who is not currently using our
						services, we recommend checking the Terms & Conditions page periodically for any updates as we will not notify
						inactive or former customers of any changes.
					</p>
					<br />

					<h5>Contact Us</h5>
					<p>
						Please get in touch with us at support@gmail.com if you have any inquiries or suggestions, or would like to
						exercise any of your statutory rights.
					</p>
					<br />

					<h6 className="text-secondary">Follow Us</h6>
					<hr />
					<div className="display-flex socialImages" style={{ paddingBottom: "10px" }}>
						<img
							src={socialMediaImg1}
							alt="Instagram"
							onClick={() => window.open("https://www.instagram.com", "_blank")}
						/>
						<img src={socialMediaImg2} alt="Linkedin" onClick={() => window.open("", "_blank")} />
						<img
							src={socialMediaImg3}
							alt="Facebook"
							onClick={() => window.open("https://www.facebook.com", "_blank")}
						/>
						<img src={socialMediaImg4} alt="Twitter" onClick={() => window.open("", "_blank")} />
						<img
							src={socialMediaImg5}
							alt="Youtube"
							onClick={() => window.open("https://www.youtube.com", "_blank")}
						/>
					</div>

					{localStorage.getItem("auth_token") ||
						(sessionStorage.getItem("auth_token") && <div className="display-flex">{BtnArray.map(buttons)}</div>)}
				</div>
			</div>
		</div>
	);
};

export default ProfessionalTermAndCondition;
